<template>
  <div>
    <app-header />

    <main v-if="!loading">
      <div
        class="
          uk-section uk-padding-remove-vertical uk-margin-remove-vertical
          in-equity-breadcrumb in-equity-breadcrumb-green
        "
      >
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <ul class="uk-breadcrumb">
                <li>
                  <router-link :to="{ name: 'siteadmin.public.home' }"
                    >Home</router-link
                  >
                </li>
                <li><span>Signals</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-section
          uk-section-primary
          uk-preserve-color
          uk-background-contain
          uk-background-center
          in-equity-15
        "
        :style="`background-image: url('${slider.background}')`"
      >
        <div class="uk-container uk-margin-top uk-margin-medium-bottom" v-if="! loading_slider">
          <div class="uk-grid">
            <div class="uk-width-3-5@m">
              <h1 class="uk-margin-small-bottom">
                <textarea
                  v-model="slider.title"
                  placeholder="Title"
                  rows="3"
                  style="font-size: 40px"
                  class="uk-textarea"
                ></textarea>
              </h1>
              <p class="uk-text-lead uk-margin-remove-top uk-text-bold">
                <textarea
                  v-model="slider.tagline"
                  placeholder="Tagline"
                  rows="3"
                  class="uk-textarea"
                ></textarea>
              </p>
              <p class="uk-margin-remove">
                <label>Change Background</label>
                <input type="file" ref="slider_background" class="uk-input" />
              </p>
              <div
                class="
                  uk-grid-large
                  uk-child-width-1-2@m
                  uk-child-width-1-2@s
                  uk-margin-medium-top
                  uk-margin-medium-bottom
                  uk-grid
                "
              >
                <div
                  class="uk-first-column"
                  v-for="(item, i) in slider.features"
                  :key="`slider-item-${i}`"
                >
                  <div class="uk-flex uk-flex-middle">
                    <div class="uk-margin-right">
                      <a
                        href="#"
                        style="font-size: 12px; color: #fff"
                        @click.prevent="openImageSelector(i)"
                        >Change</a
                      >
                      <input
                        type="file"
                        :id="`slider_featured_item_${i}`"
                        ref="slider_featured"
                        @change="changeFeaturedIcon(i)"
                        style="display: none"
                      />
                      <template v-if="!changing_icon">
                        <img
                          :src="item.icon"
                          alt="icon-1"
                          width="96"
                          v-if="changed_icons[i] == false"
                          style="width: 96px"
                        />
                        <image-preview
                          v-if="changed_icons[i] != false"
                          :image="changed_icons[i]"
                          style="width: 96px"
                        />
                      </template>
                    </div>
                    <div>
                      <p class="uk-margin-remove uk-text-emphasis">
                        <input
                          type="text"
                          v-model="item.title"
                          placeholder="Title"
                          class="uk-input"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="uk-flex uk-flex-left uk-flex-middle get-started">
                <a
                  href="#"
                  @click.prevent="updateSlider"
                  class="
                    uk-button uk-button-primary uk-border-rounded uk-margin-left
                  "
                  >Save</a
                >
              </div>
            </div>
          </div>
        </div>

        <loading v-if="loading_slider" />
      </div>

      <div class="uk-section in-equity-3 in-offset-top-20">
        <signals-plans :loading="loading" :signals="signals" />
      </div>

      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        style="background-image: url('/new/in-decor-2.svg')"
      >
        <getting-started
          :loading="loading"
          :getting_started="getting_started"
        />
      </div>
    </main>

    <app-footer :disclaimer="disclaimer" :loading="loading" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import SignalsPlans from "../shared/SignalsPlans";
import GettingStarted from "../shared/GettingStarted";

export default {
  components: {
    AppHeader,
    AppFooter,
    SignalsPlans,
    GettingStarted,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      signals: {},
      getting_started: {},
      disclaimer: "",
      slider: {},
      loading_slider: false,
      changed_icons: [false, false, false, false],
      changing_icon: false,
    };
  },

  methods: {
    changeFeaturedIcon(i) {
      this.changed_icons[i] = this.$refs.slider_featured[i].files[0];
      this.changing_icon = true;

      setTimeout(() => {
        this.changing_icon = false;
      }, 10);
    },

    openImageSelector(i) {
      document.getElementById(`slider_featured_item_${i}`).click();
    },

    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/sitecontent/public-signals").then((response) => {
        this.slider = response.data.slider;
        this.signals = response.data.signals;
        this.getting_started = response.data.getting_started;
        this.disclaimer = response.data.disclaimer;
        this.loading = false;
      });
    },

    updateSlider() {
      this.loading_slider = true;

      let data = new FormData();
      data.append("title", this.slider.title);
      data.append("tagline", this.slider.tagline);

      if (this.$refs.slider_background.files[0]) {
        data.append("background", this.$refs.slider_background.files[0]);
      }

      this.slider.features.forEach((item, i) => {
        data.append(`features[${i}][title]`, item.title);

        if (this.changed_icons[i]) {
          data.append(`features[${i}][icon]`, this.changed_icons[i]);
        }
      });

      this.$axios
        .post("/api/v1/admin/sitecontent/public-signals-slider", data)
        .then((response) => {
          this.$toasted.success("Updated");
          this.slider = response.data.slider;
          this.loading_slider = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="uk-container uk-margin-large-bottom" v-if="!updating">
      <div class="uk-grid uk-flex uk-flex-middle">
        <div class="uk-width-expand@m">
          <h1 class="uk-margin-small-bottom">
            <textarea
              placeholder="Title"
              v-model="signals.title"
              rows="3"
              style="font-size: 30px"
              class="uk-textarea"
            ></textarea>
          </h1>
          <ul class="uk-list in-list-check">
            <li
              v-for="(item, i) in signals.items"
              :key="`signals-item-${i}`"
            >
              <div class="uk-inline uk-width-100">
                <a
                  class="uk-form-icon uk-form-icon-flip uk-text-danger"
                  href="#"
                  @click.prevent="signals.items.splice(i, 1)"
                >
                  <i class="fa fa-trash"></i>
                </a>
                <input
                  class="uk-input"
                  type="text"
                  aria-label="Not clickable icon"
                  placeholder="Content"
                  v-model="signals.items[i]"
                />
              </div>
            </li>
          </ul>
          <div style="float: right">
            <a href="#" @click.prevent="signals.items.push('')">Add</a>
          </div>
          <a
            href="#"
            @click.prevent="submit"
            class="uk-button uk-button-primary uk-button-large"
            >Save<i class="fas fa-arrow-circle-right uk-margin-small-left"></i
          ></a>
        </div>
        <div class="uk-width-2xlarge uk-flex uk-flex-right uk-flex-center@s">
          <div
            class="
              uk-card uk-card-body uk-card-default uk-border-rounded
              in-margin-top-60@s
            "
          >
            <div id="tradingview-widget">
              <a
                :href="$router.resolve({ name: 'admin.pricing.index' }).href"
                class="uk-float-right"
                target="_blank"
                >Edit Pricing</a
              >
              <div style="width: 100%">
                <table class="pricing-table">
                  <tbody>
                    <tr>
                      <td
                        style="
                          border-bottom: solid 1px #ddd;
                          border-right: solid 1px #ddd;
                        "
                      >
                        <h3>1 Month</h3>
                        <h1>
                          $ {{ signals.plans[1].whole }}
                          <small>.{{ signals.plans[1].cent }}</small>
                        </h1>
                      </td>
                      <td style="border-bottom: solid 1px #ddd">
                        <h3>3 Months</h3>
                        <h1>
                          $ {{ signals.plans[3].whole }}
                          <small>.{{ signals.plans[3].cent }}</small>
                        </h1>
                      </td>
                    </tr>
                    <tr>
                      <td style="border-right: solid 1px #ddd">
                        <h3>6 Months</h3>
                        <h1>
                          $ {{ signals.plans[6].whole }}
                          <small>.{{ signals.plans[6].cent }}</small>
                        </h1>
                      </td>
                      <td>
                        <h3>12 Months</h3>
                        <h1>
                          $ {{ signals.plans[12].whole }}
                          <small>.{{ signals.plans[12].cent }}</small>
                        </h1>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="updating" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      updating: this.loading,
      sig: this.signals,
    };
  },

  props: ["loading", "signals"],

  methods: {
    submit() {
      this.updating = true;

      this.$axios
        .post("/api/v1/admin/sitecontent/public-sugnals-signals", this.signals)
        .then((response) => {
          this.$toasted.success("Updated");
          this.signals = response.data.signals;
          this.updating = false;
        });
    },
  },

  watch: {
    loading(newval) {
      this.updating = newval;
    },
  },
};
</script>
